import { useForm } from 'react-hook-form';
import LabelForm from './LabelFrom';
import { useState } from 'react';
import { Puff } from 'react-loader-spinner'
import { preciosTlcPalmBeach, preciosTlcPrincipal } from '../providers/prices';
import '../App.css';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';

export default function FormularioRegistro2() {

    const [isLoading, setIsLoading] = useState(false)
    const [ estimatePrice, setEstimatePrice ] = useState('Choose county, size and service')

    const { register, handleSubmit, formState: { errors }, watch, setValue, reset, setError, clearErrors } = useForm({
        defaultValues: {
            date: formatearFecha(new Date()),
            time: '',
            price: 0
        },
    })

    const onSubmit = handleSubmit(datos => {
        if (watch('size') === null) {
            setError("size", { type: "custom", message: "Choose the size of your pet" })
            return
        }
        if (watch('service') === null) {
            setError("service", { type: "custom", message: "Choose the type of service" })
            return
        }

        let datosUser = new FormData();
        datosUser.append('Fecha Solicitud', new Date())
        for (const key in datos) {
            switch (key) {
                case 'date': datosUser.append('Fecha', datos[key])
                    break;
                case 'time': datosUser.append('Hora', datos[key])
                    break;
                case 'name': datosUser.append('Nombre', datos[key])
                    break;
                case 'lastName': datosUser.append('Apellido', datos[key])
                    break;
                case 'cellPhone': datosUser.append('Celular', datos[key])
                    break
                case 'email': datosUser.append('Correo', datos[key])
                    break;
                case 'zipCode': datosUser.append('Zip Code', datos[key])
                    break;
                case 'promo': datosUser.append('Promo', datos[key])
                    break;
                case 'price': datosUser.append('Total', datos[key])
                    break;
                case 'petSize': datosUser.append('Size', datos[key])
                    break;
                case 'service': datosUser.append('Servicio', datos[key])
                    break;
                case 'county':
                    if (datos[key] === 'Broward') {
                        datosUser.append('hoja_destino', 'TLC Broward')
                    } else if (datos[key] === 'Miami') {
                        datosUser.append('hoja_destino', 'TLC Broward')
                    } else if (datos[key] === 'Palm Beach') {
                        datosUser.append('hoja_destino', 'TLC Palm Beach')
                    }
                    break;

                default:
                    break;
            }
        }
        const scriptURL = 'https://script.google.com/macros/s/AKfycbyp760B8Z8BPzWjtHOSqWGYsC4fzKb6aTedgKdVMoRn1UGhrjSAIbsEmLyh1JReyWRXoQ/exec'
        setIsLoading(true)
        fetch(scriptURL, { method: 'POST', body: datosUser })
            .then(response => response.json()).then(data => {
                if (data.result === 'success') {
                    reset()
                    Swal.fire({
                        title: "Booking Successful",
                        text: "An agent will contact you soon to provide details.",
                        icon: "success"
                    });
                }
            })
            .catch(error => console.error('Error!', error.message))
            .finally(() => {
                setIsLoading(false)
            })

    })

    /*function generateTimeSlots() {
        const timeSlots = [];
        const addTimeSlot = (hours, minutes) => {
            // Formatea las horas y minutos en un string "HH:MM"
            const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            timeSlots.push(time);
        };

        // Franja horaria AM
        for (let hour = 8; hour < 12; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                if (hour === 8 && minute === 0) {
                    minute = 30; // Comienza en 8:30 AM
                }
                if (hour === 11 && minute === 45) {
                    addTimeSlot(hour, minute);
                    break; // Termina en 11:45 AM
                }
                addTimeSlot(hour, minute);
            }
        }

        // Franja horaria PM
        for (let hour = 12; hour <= 18; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                if (hour === 18 && minute > 30) {
                    break; // Termina en 6:30 PM
                }
                addTimeSlot(hour, minute);
            }
        }

        return timeSlots;
    }

    const timeSlotsArray = generateTimeSlots();*/

    function updatePrices(value) {
        let objPrecios = {};
        switch (watch('county')) {
            case 'Broward':
                objPrecios = {
                    basicGrooming: preciosTlcPrincipal[value]?.basicGrooming ?? { price: 0, estimated: 'Choose county, size and service' },
                    deShedding: preciosTlcPrincipal[value]?.deShedding ?? { price: 0, estimated: 'Choose county, size and service' },
                    fullGrooming: preciosTlcPrincipal[value]?.fullGrooming ?? { price: 0, estimated: 'Choose county, size and service' }
                }
                break;
            case 'Miami':
                objPrecios = {
                    basicGrooming: preciosTlcPrincipal[value]?.basicGrooming ?? { price: 0, estimated: 'Choose county, size and service' },
                    deShedding: preciosTlcPrincipal[value]?.deShedding ?? { price: 0, estimated: 'Choose county, size and service' },
                    fullGrooming: preciosTlcPrincipal[value]?.fullGrooming ?? { price: 0, estimated: 'Choose county, size and service' }
                }
                break;
            case 'Palm Beach':
                objPrecios = {
                    basicGrooming: preciosTlcPalmBeach[value]?.basicGrooming ?? { price: 0, estimated: 'Choose county, size and service' },
                    deShedding: preciosTlcPalmBeach[value]?.deShedding ?? { price: 0, estimated: 'Choose county, size and service' },
                    fullGrooming: preciosTlcPalmBeach[value]?.fullGrooming ?? { price: 0, estimated: 'Choose county, size and service' }
                }
                break;
            default:
                objPrecios = {
                    basicGrooming: { price: 0, estimated: 'Choose county, size and service' },
                    deShedding: { price: 0, estimated: 'Choose county, size and service' },
                    fullGrooming: { price: 0, estimated: 'Choose county, size and service' }
                }
                break
        }
        if (watch('service') === 'basic-grooming') {
            setValue('price', objPrecios.basicGrooming.price)
            setEstimatePrice(objPrecios.basicGrooming.estimated)
        } else if (watch('service') === 'full-grooming') {
            setValue('price', objPrecios.fullGrooming.price)
            setEstimatePrice(objPrecios.fullGrooming.estimated)
        } else if (watch('service') === 'de-shedding') {
            setValue('price', objPrecios.deShedding.price)
            setEstimatePrice(objPrecios.deShedding.estimated)
        } else {
            setValue('price', 0)
            setEstimatePrice('Choose county, size and service')
        }
    }

    function formatearFecha(date) {
        let dia = date.getDate();
        let mes = date.getMonth() + 1; // Los meses comienzan desde 0
        let año = date.getFullYear();
      
        // Añade un cero si el día o mes es menor a 10
        dia = dia < 10 ? '0' + dia : dia;
        mes = mes < 10 ? '0' + mes : mes;
      
        return mes + '/' + dia + '/' + año;
    }

    return <form className='cont-form form2' onSubmit={onSubmit}>
        <div className='cont-form-top-der'>
            <div className='cont-input-item'>
                <LabelForm name={'County'} icono={'county'} />
                <select {...register('county', {
                    required: {
                        value: true,
                        message: 'Select the county'
                    },
                    onChange: e => updatePrices(watch('petSize'))
                })}>
                    <option value=''>Select the county</option>
                    <option value='Broward'>Broward</option>
                    <option value='Miami'>Miami</option>
                    <option value='Palm Beach'>Palm Beach</option>
                </select>
                <span>{!!errors['county'] && errors['county'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Pet Size'} icono={'paw'} />
                <select {...register('petSize', {
                    required: {
                        value: true,
                        message: 'Choose the size of your pet'
                    },
                    onChange: e => updatePrices(e.target.value)
                })}>
                    <option value=''>Choose the size</option>
                    <option value='Small'>Small 1-15 Pounds</option>
                    <option value='Medium'>Medium 16-40 Pounds</option>
                    <option value='Large'>Large 41-70 Pounds</option>
                    <option value='Xlarge'>X-Lg 71-100 Pounds</option>
                </select>
                <span>{!!errors['petSize'] && errors['petSize'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Service'} icono={'paw'} />
                <select {...register('service', {
                    required: {
                        value: true,
                        message: 'Select the service'
                    },
                    onChange: e => updatePrices(watch('petSize'))
                })}>
                    <option value=''>Select the service</option>
                    <option value='full-grooming'>Full Grooming</option>
                    <option value='de-shedding'>De-shedding</option>
                    <option value='basic-grooming'>Basic Grooming</option>
                </select>
                <span>{!!errors['service'] && errors['service'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Promo'} icono={'gift'} />
                <select {...register('promo', {
                    required: {
                        value: true,
                        message: 'Select the promo'
                    },
                })}>
                    <option value=''>Select the promo</option>
                    <option value='FREE Teeth Brushing'>FREE Teeth Brushing</option>
                    <option value='FREE Flea Dip'>FREE Flea Dip</option>
                </select>
                <span>{!!errors['promo'] && errors['promo'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Cell Phone'} icono={'cell'} />
                <input type='number' {...register('cellPhone', {
                    required: {
                        value: true,
                        message: 'The Cell Phone is required'
                    }
                })} />
                <span>{!!errors['cellPhone'] && errors['cellPhone'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'First Name'} icono={'person'} />
                <input type='text' {...register('name', {
                    required: {
                        value: true,
                        message: 'The name is required'
                    },
                })} />
                <span>{!!errors['name'] && errors['name'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Last Name'} icono={'person'} />
                <input type='text' {...register('lastName', {
                    required: {
                        value: true,
                        message: 'The Last Name is required'
                    }
                })} />
                <span>{!!errors['lastName'] && errors['lastName'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Day'} icono={'date'} />
                <DatePicker
                    selected={watch('date') !== '' ? new Date(watch('date')) : new Date()}
                    onChange={(date) => {
                        try {
                            const fechaFormateada = formatearFecha(date);
                            setValue('date', fechaFormateada)
                            clearErrors('date')
                        } catch (error) {
                            setError('date', { type: 'custom', message: 'Select the day of your appointment' })
                        }
                    }}
                    includeDateIntervals={[
                        { start: new Date(), end: addDays(new Date(), 30) },
                    ]}
                    placeholderText="Select the day of your appointment"
                    dateFormat="dd/MM/yyyy"
                />
                <span>{!!errors['date'] && errors['date'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Time Frame'} icono={'time'} />
                <select {...register('time', {
                    required: {
                        value: true,
                        message: 'Select your appointment time'
                    },
                })}>
                    <option value=''>Select your appointment time</option>
                    {/*/timeSlotsArray.map((hour, index) => (
                        <option key={index} value={hour}>
                            {hour}
                        </option>
                    ))**/}
                    <option value="AM (8:30am-11:45am)" >{ 'AM (8:30am-11:45am)' }</option>
                    <option value="PM (12:00pm-6:00pm)" >{ 'PM (12:00pm-6:00pm)' }</option>
                    <option value="Anytime (8:30am-6:00pm)" >{ 'Anytime (8:30am-6:00pm)' }</option>
                </select>
                <span>{!!errors['time'] && errors['time'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Estimated Price'} icono={'price'} />
                <input style={{ textAlign: 'center' }} readOnly type='text' value={ estimatePrice } />
            </div>
            {isLoading ?
                <div className='cont-btn-loading'>
                    <Puff
                        visible={true}
                        height="40"
                        width="40"
                        color="#E51F5F"
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                    <p>Processing...</p>
                </div>
                : <div className='cont-btn-book'>
                    <button className='btn-send'>Book Now</button>
                    <p>Get <strong>10% Discount</strong>, Ask Us How! </p>
                </div>}
        </div>
        <div className='form2-patitas'>
            <svg viewBox="0 0 262 227" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path d="M192.741 144.757C186.793 141.341 186.039 132.687 191.058 125.426C196.077 118.165 204.968 115.048 210.917 118.463C216.865 121.878 217.619 130.533 212.6 137.794C207.581 145.055 198.69 148.172 192.741 144.757Z" fill="#E02563" />
                </g>
                <g>
                    <path d="M180.241 147.573C178.052 153.394 172.07 156.725 166.864 154.999C161.666 153.291 159.226 147.164 161.402 141.339C163.591 135.519 169.572 132.188 174.779 133.913C179.985 135.639 182.417 141.749 180.241 147.573Z" fill="#E02563" />
                </g>
                <g>
                    <path d="M221.42 162.97C215.472 159.555 214.718 150.9 219.737 143.639C224.756 136.379 233.647 133.261 239.596 136.676C245.544 140.092 246.298 148.747 241.279 156.007C236.26 163.268 227.369 166.386 221.42 162.97Z" fill="#E02563" />
                </g>
                <g>
                    <path d="M219.546 188.651C216.075 184.713 217.417 178.311 222.544 174.351C227.67 170.392 234.64 170.375 238.111 174.313C241.582 178.25 240.239 184.652 235.113 188.612C229.986 192.571 223.017 192.589 219.546 188.651Z" fill="#E02563" />
                </g>
                <g>
                    <path d="M167.81 206.678C167.093 206.23 166.331 205.638 165.619 204.892C164.01 203.226 163.419 201.54 162.288 198.226C161.815 196.834 161.261 194.989 160.828 192.743C159.676 192.293 157.762 191.394 155.973 189.655C155.266 188.968 153.059 186.75 152.393 183.333C151.64 179.405 153.39 176.298 154.21 174.877C157.726 168.698 164.553 166.707 166.644 166.101C168.31 165.611 171.173 164.817 174.898 165.274C177.69 165.623 179.823 166.538 181.139 167.212C181.713 166.917 182.57 166.417 183.404 165.611C184.491 164.561 184.996 163.533 185.168 163.192C186.514 160.692 192.859 155.998 199.208 157.459C203.071 158.345 205.233 161.102 205.478 161.453C209.244 166.429 206.873 173.651 204.877 175.724C204.609 176.006 203.762 176.807 203.113 178.144C202.613 179.159 202.425 180.078 202.349 180.692C203.499 181.582 205.197 183.106 206.563 185.389C208.388 188.448 208.641 191.231 208.763 192.849C208.919 194.89 209.425 201.538 204.543 206.841C203.415 208.062 200.962 210.724 196.764 211.504C193.108 212.179 190.09 211.073 189.144 210.705C186.754 209.792 185.158 208.457 184.289 207.617C181.893 208.052 179.834 208.257 178.29 208.346C174.579 208.576 172.683 208.678 170.421 207.892C169.944 207.735 168.958 207.354 167.831 206.628L167.81 206.678Z" fill="#E02563" />
                </g>
                <g>
                    <path d="M68.0678 59.6379C57.3256 56.0247 53.2605 42.8216 58.9882 30.1479C64.7158 17.4743 78.0672 10.1294 88.8094 13.7426C99.5515 17.3558 103.617 30.5589 97.889 43.2325C92.1613 55.9062 78.8099 63.2511 68.0678 59.6379Z" fill="#E02563" />
                </g>
                <g>
                    <path d="M48.7815 67.5366C47.1563 77.1775 38.5794 84.031 29.5934 82.8279C20.6261 81.6485 14.6656 72.8404 16.2679 63.1965C17.8932 53.5556 26.47 46.702 35.4561 47.9052C44.4421 49.1083 50.3838 57.8927 48.7815 67.5366Z" fill="#E02563" />
                </g>
                <g>
                    <path d="M120.432 79.7667C109.69 76.1535 105.625 62.9504 111.353 50.2767C117.081 37.6031 130.432 30.2582 141.174 33.8714C151.916 37.4846 155.981 50.6877 150.254 63.3613C144.526 76.035 131.175 83.3799 120.432 79.7667Z" fill="#E02563" />
                </g>
                <g>
                    <path d="M125.848 120.104C118.94 114.981 119.005 104.678 125.992 97.0913C132.98 89.5051 144.244 87.5086 151.152 92.6321C158.059 97.7555 157.994 108.059 151.007 115.645C144.02 123.231 132.756 125.228 125.848 120.104Z" fill="#E02563" />
                </g>
                <g>
                    <path d="M48.1204 162.67C46.8144 162.177 45.3876 161.475 43.9899 160.52C40.8407 158.393 39.3308 155.946 36.4122 151.128C35.1898 149.104 33.6862 146.401 32.2484 143.042C30.2365 142.67 26.8458 141.817 23.3812 139.627C22.0118 138.761 17.7143 135.947 15.5135 130.839C13.003 124.962 14.8109 119.652 15.6694 117.216C19.3223 106.646 29.7076 101.629 32.8895 100.099C35.4222 98.8682 39.7895 96.8297 45.9642 96.4852C50.5931 96.2373 54.3438 97.0519 56.6921 97.7248C57.5249 97.1062 58.7458 96.0888 59.8292 94.6036C61.2407 92.6689 61.719 90.9327 61.8861 90.355C63.2401 86.0997 71.9564 77.03 82.7032 77.5011C89.2407 77.7826 93.6433 81.4458 94.155 81.92C101.881 88.5688 100.423 100.435 97.8773 104.212C97.5361 104.724 96.4299 106.207 95.8204 108.461C95.3465 110.176 95.3445 111.654 95.4236 112.628C97.5753 113.682 100.822 115.565 103.782 118.718C107.739 122.943 109.063 127.187 109.793 129.66C110.717 132.78 113.722 142.941 107.572 152.541C106.149 154.753 103.058 159.572 96.5275 161.969C90.8373 164.048 85.5939 163.187 83.9433 162.884C79.7782 162.143 76.7578 160.525 75.0761 159.469C71.3446 160.82 68.0827 161.719 65.6158 162.294C59.6901 163.699 56.6587 164.393 52.7417 163.813C51.9191 163.705 50.2 163.393 48.1377 162.587L48.1204 162.67Z" fill="#E02563" />
                </g>
            </svg>
        </div>
    </form>
}